(function ($) {
	$('[data-slick-camere]').slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		dots: true,
		arrows: true,
		responsive: [{

			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}

		}, {

			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				centerMode: true,
				centerPadding: '30px',
			}

		}]
	});
	$('[data-slick-meeting]').slick({
		mobileFirst: true,
		responsive: [{

			breakpoint: 1100,
			settings: "unslick",

		}, {

			breakpoint: 1099,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				dots: true,
				arrows: true,
			}

		}, {

			breakpoint: 1023,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}

		}, {

			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				centerMode: true,
				centerPadding: '30px',
			}

		}]
	});
	$('[data-slick-rooms]').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		//dots: true,
		arrows: true,
		responsive: [{

			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1
			}

		}, {

			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				//centerMode: true,
				centerPadding: '30px',
			}

		}]
	});
	$('[data-slick-room-pictures]').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 2500,
		dots: true,
		arrows: true,
		fade: true,
	});
})(jQuery);